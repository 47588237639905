import React, { Suspense } from 'react'
// import App from 'next/app'
import { Provider } from 'react-redux'
// import withRedux from 'next-redux-wrapper'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter, Router } from 'react-router-dom'

// import { DefaultSeo } from 'next-seo'
// import nextCookie from 'next-cookies'
import { getConfig } from '@config'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import { createIntlCache, RawIntlProvider, createIntl } from 'react-intl'

// import translate messages
import translations from '../i18n/locales'

// import SEO from '../next-seo.config'

// with redux store
import withReduxStore from '../hocs/with-redux-store'

// hooks
import GlobalContext, { GlobalProvider, globalStore } from '../hooks/useGlobal'
import { LazyImageProvider } from '../vendor/lazy-image/LazyImageContext'

// CSS Global Compulsory
import '@assets/vendor/bootstrap/bootstrap.min.css'
import '@assets/vendor/bootstrap/offcanvas.css'
import '@assets/vendor/icon-awesome/scss/font-awesome.scss'
import '@assets/vendor/icon-line/css/simple-line-icons.css'
import '@assets/vendor/icon-etlinefont/style.css'
import '@assets/vendor/icon-line-pro/style.css'
import '@assets/vendor/icon-hs/style.css'
import '@assets/vendor/animate.css'
import '@assets/vendor/hamburgers/hamburgers.min.css'
import '@assets/vendor/hs-megamenu/src/hs.megamenu.css'
import '@assets/vendor/fancybox/jquery.fancybox.css'
import '@assets/vendor/icon-awesome-pro/css/all.min.css'


// CSS Unify
import './../scss/unify.scss'

// Ant Design
import 'antd/dist/antd.css'
import 'rc-drawer/assets/index.css'

//Slick
import 'slick-carousel/slick/slick.scss'
import 'slick-carousel/slick/slick-theme.scss'

import Sentry from '@utils/sentry'
import { currencyUnit } from '../constants/currencyUnit'
import { Cookie, gtmUtils } from '../utils'
import { get } from 'lodash'
import { checkAuth } from '../actions/authAction'
import Routes from '../components/Layout/Routes'
import PageLoading from '../components/Layout/PageLoading'
import History from '@utils/router/history'
import RoutesWithLocale from 'src/components/Layout/RoutesWithLocale'
import {LocalStorage} from "@utils/"
import { LOCAL_STORAGE_KEY } from 'src/constants'
import { DDAddAction } from 'src/hooks/DD_RUM'
// import { datadogRum } from "@datadog/browser-rum";

// This is optional but highly recommended
// since it prevents memory leak
// const cache = createIntlCache()
const { captureException } = Sentry()

// This is optional but highly recommended
// since it prevents memory leak
// const cache = createIntlCache()
// const isServer = typeof window === 'undefined'

const {
  publicRuntimeConfig: {
    NODE_ENV,
    RECAPTCHA_KEY,
  } // Available both client and server side
} = getConfig()

const CAPTCHA_HARD = '6LfAeRMdAAAAAKOF0sP0sSeAF8NPYEfXjdGBP1Wr'

if (!NODE_ENV === 'development') {
  const noop = () => { }

  // TODO: uncomment when deploy
  console.log = noop
  console.warn = noop
  console.error = noop
}

class MyApp extends React.Component {
  constructor(props) {
    super(props)
    const pageProps = this.getInitialProps()
    this.state = {
      pageProps,
      hasError: false,
      errorEventId: pageProps.errorEventId || undefined,
      loaded: false,
    }

    this.persistor = persistStore(props.reduxStore)
  }

  getInitialProps() {
    try {
      // let pageProps = {}
      // const contextCookies = nextCookie(ctx)

      const lang = Cookie.get(Cookie.STORAGE_KEY.LANGUAGE)
      const currency = Cookie.get(Cookie.STORAGE_KEY.CURRENCY)
      const auth = Cookie.get(Cookie.STORAGE_KEY.AUTH)
      const userAgent = window.navigator.userAgent;

      globalStore.state.lang = lang;
      globalStore.state.currency = currency;
      globalStore.state.auth = auth;
      globalStore.state.userAgent = userAgent;

      //init props components
      // if (Component.getInitialProps) {
      //   pageProps = await Component.getInitialProps(ctx)
      // }

      // console.log('=====> Context Cookie _app', lang)

      return {
        // pageProps,
        lang: lang || language.Vietnamese,
        currency: currency || currencyUnit.VietnameseDong,
        userAgent,
      }
    } catch (error) {
      const errorEventId = captureException(error)
      return {
        hasError: true,
        errorEventId
      }
    }
  }

  // static getDerivedStateFromProps(props, state) {
  //   // If there was an error generated within getInitialProps, and we haven't
  //   // yet seen an error, we add it to this.state here
  //   return {
  //     hasError: props.hasError || state.hasError || false,
  //     errorEventId: props.errorEventId || state.errorEventId || undefined
  //   }
  // }

  // static getDerivedStateFromError() {
  //   // React Error Boundary here allows us to set state flagging the error (and
  //   // later render a fallback UI).
  //   return { hasError: true }
  // }

  componentDidCatch(error, errorInfo) {
    const errorEventId = captureException(error, { errorInfo })
    console.error('_app Error', error)
    // Store the event id at this point as we don't have access to it within
    // `getDerivedStateFromError`.
    this.setState({ errorEventId })
  }

  componentDidMount() {
    const { reduxStore } = this.props;
    // Auto login
    const ua = navigator.userAgent.toLowerCase(); 
    if (ua.indexOf('gecko/') !== -1 || ua.indexOf('chrome/') !== -1) { 
      reduxStore.dispatch(checkAuth());
    }
    const loadTimeout = setTimeout(() => {
      this.setState({ loaded: true })
      clearTimeout(loadTimeout)
    }, 1500)
    this.unlisten = History.listen(({ action, location }) => {
      document.body.scrollTop = 0
    })
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    const { reduxStore } = this.props
    const { loaded, pageProps } = this.state;
    const { lang, currency, userAgent } = pageProps;

    return (
      <GoogleReCaptchaProvider reCaptchaKey={CAPTCHA_HARD}>
        <GlobalProvider value={{ lang, currency, userAgent }} translations={translations}>
          <Provider store={reduxStore}>
            <Suspense fallback={PageLoading}>
              <BrowserRouter basename={process.env.PUBLIC_URL}>
                <PersistGate
                  persistor={this.persistor}
                  loading={<Routes />}
                >
                  {/*<NextSeo config={SEO} />*/}
                  {/* <DefaultSeo {...SEO} /> */}
                    <LazyImageProvider>
                      {/* <Routes /> */}
                      <RoutesWithLocale />
                    </LazyImageProvider>
                </PersistGate>
              </BrowserRouter>
            </Suspense>
          </Provider>
        </GlobalProvider>
      </GoogleReCaptchaProvider >
    )
  }
}

export function reportWebVitals(metric) {
  return gtmUtils.webVitalsHandle(metric)
}

export default withReduxStore(MyApp)
