// NOTE: This require will be replaced with `@sentry/browser`
// client side thanks to the webpack config in next.config.js
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import Cookie from 'js-cookie'
import { getConfig } from '@config'
import packageJson from '../../package.json';

const {
  publicRuntimeConfig: { SENTRY_DSN } // Available both client and server side
} = getConfig();

export default () => {

  // When we're developing locally
  if (process.env.REACT_APP_NODE_ENV !== 'production' && process.env.REACT_APP_NODE_ENV !== 'beta') {
    return { Sentry, captureException: () => null }
  }

  const sentryOptions = {
    dsn: SENTRY_DSN,
    release: packageJson.version,
    // integrations: [new Integrations.BrowserTracing()],
    maxBreadcrumbs: 50,
    attachStacktrace: true,
    // tracesSampleRate: 1.0,
    //------------------old-----------------
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/booking.vinpearl\.com/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  }
  // console.log(sentryOptions, process.env)

  if (!!sentryOptions.dsn) {
    Sentry.init(sentryOptions);
  }




  return {
    Sentry,
    captureException: (err, ctx) => {
      Sentry.withScope(scope => {
        if (err.message) {
          // De-duplication currently doesn't work correctly for SSR / browser errors
          // so we force deduplication by error message if it is present
          scope.setFingerprint([err.message])
        }

        if (err.statusCode) {
          scope.setExtra('statusCode', err.statusCode)
        }

        if (ctx) {
          const { req, res, errorInfo, query, pathname } = ctx

          if (res && res.statusCode) {
            scope.setExtra('statusCode', res.statusCode)
          }

          if (typeof window !== 'undefined') {
            scope.setTag('ssr', false)
            scope.setExtra('query', query)
            scope.setExtra('pathname', pathname)

            // On client-side we use js-cookie package to fetch it
            const sessionId = Cookie.get('sid')
            if (sessionId) {
              scope.setUser({ id: sessionId })
            }
          } else {
            scope.setTag('ssr', true)
            scope.setExtra('url', req.url)
            scope.setExtra('method', req.method)
            scope.setExtra('headers', req.headers)
            scope.setExtra('params', req.params)
            scope.setExtra('query', req.query)

            // On server-side we take session cookie directly from request
            if (req.cookies && req.cookies.sid) {
              scope.setUser({ id: req.cookies.sid })
            }
          }

          if (errorInfo) {
            Object.keys(errorInfo).forEach(key =>
              scope.setExtra(key, errorInfo[key])
            )
          }
        }
      })

      return Sentry.captureException(err)
    },
  }
}
